import springReportsApi from '../../services/springReportsApi'

export const campaignsGeneralReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/general`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const marketingKpisReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/marketing_kpis`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const salesKpisReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/sales_kpis`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const invoicesTableReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/invoices_table`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const categoriesProductsReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/categories_products`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const productsTableReport = (data) => (
	new Promise((resolve, reject) => {
		springReportsApi()
			.post(`/campaigns/products_table`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)