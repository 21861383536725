import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faCashRegister, faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons'

import PageTitle from './partials/PageTitle'
import MenuWidget from './partials/MenuWidget'

export default function CampaignsDashboard() {
	return (
		<React.Fragment>
			<PageTitle title="Ventas Momentos Spring" subtitle="Reportes relacionados a las ventas asociadas al programa de Momentos Spring." />
			<Row>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Reporte General de Campañas"
						subtitle="Listado de Campañas de Email Marketing"
						icon={faEnvelopesBulk}
						linkTo={"/campaigns/general"}
					/>
				</Col>
				<Col sm="12" md="6" lg="4" className="mb-10">
					<MenuWidget 
						title="Reporte Avanzado de Campañas"
						subtitle="Análisis de Campañas Momentos Spring"
						icon={faCashRegister}
						linkTo={"/campaigns/advance"}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}