import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

import CampaignsTable from './partials/CampaignsTable'

import { campaignsGeneralReport } from './services'
import { handleError } from '../../helpers'

export default function GeneralCampaignReport() {
    const [campaigns, setCampaigns] = useState(null)

    useEffect(() => {
        !campaigns && campaignsGeneralReport({})
            .then(res => setCampaigns(res.data.data.data))
            .catch(error => handleError(error))
    }, [ campaigns ])

    return (
        <React.Fragment>
            <Card>
				<CardBody>
					<div className='float-right'>
						<Link to="/dashboards/campaigns">
							<Button color="primary" outline>Ver otros reportes</Button>
						</Link>
					</div>
					<CardTitle>Reporte General de Campañas</CardTitle>
					<CardSubtitle>Campañas de email marketing enviadas masivamente</CardSubtitle>
				</CardBody>
			</Card>
            <Card>
				<CardBody>
                    <CampaignsTable 
                        campaigns={campaigns}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}