import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Empty, message, Result, Table } from 'antd'

import { categoriesProductsReport } from '../../services'
import { handleError, number } from '../../../../helpers'

export default function CategoriesProductsTab({ reports, setReports, filters, activeKey, setLoading }) {
    const [currentParams, setCurrentParams] = useState(null)
    
    useEffect(() => {
        if(activeKey === 'categories_products' && (!reports || !reports.categories_products) && filters.sent_at_from){
            categoriesProductsReport(filters)
                .then(res => {
                    message.success(res.data.message)
                    setReports({ ...reports, categories_products: res.data.data })
                    setCurrentParams(res.data.params)                    
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading ])

    if(!filters.sent_at_from)
        return <Empty description="Debe generar el reporte primero" />

    if(!reports || !reports.categories_products)
        return <Spinner animation='border' size='sm' />

    const columns = [
        {
            title: 'Categoría',
            dataIndex: 'category',
        },
        {
            title: 'Total Facturas',
            dataIndex: 'total_invoices',
            render: t => number(t)
        },
        {
            title: 'Total Tiendas',
            dataIndex: 'total_stores',
            render: t => number(t)
        },
        {
            title: 'Total Vendido',
            dataIndex: 'total_value',
            render: t => `$ ${number(t)} COP`
        },
        {
            title: 'Total Productos',
            dataIndex: 'total_products',
            render: t => number(t)
        },
        {
            title: 'Total Unidades',
            dataIndex: 'total_quantity',
            render: t => number(t)
        },
    ]

    return (
        <React.Fragment>
            <div className='bg-light py-5 px-15 mb-10'>
                <em>Reporte generado para campañas <b>con facturas sincronizadas</b> con fecha de envió entre <b>{currentParams.sent_at_from}</b> y <b>{currentParams.sent_at_until}</b> {currentParams.store_code && ` Y facturas de tienda cuyo codigo contenga: ${currentParams.store_code}`}</em>.
            </div>
            
            <Table
                columns={columns}
                dataSource={reports.categories_products.categories}
                rowKey={r => r.id}
                locale={{ emptyText: <Result title="No se encontro resultados" status="404" /> }}
                size="small"
            />

            <div className='mt-10'>
                <small>Campañas analizadas: {reports.categories_products.campaign_ids.join(', ')}</small>
            </div>
        </React.Fragment>
    )
}