import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Empty, message, Result, Table } from 'antd'

import { productsTableReport } from '../../services'
import { handleError, number } from '../../../../helpers'

export default function ProductsTableTab({ reports, setReports, filters, activeKey, setLoading }) {
    const [currentParams, setCurrentParams] = useState(null)
    
    useEffect(() => {
        if(activeKey === 'products_table' && (!reports || !reports.products_table) && filters.sent_at_from){
            productsTableReport(filters)
                .then(res => {
                    message.success(res.data.message)
                    setReports({ ...reports, products_table: res.data.data })
                    setCurrentParams(res.data.params)                    
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading ])

    if(!filters.sent_at_from)
        return <Empty description="Debe generar el reporte primero" />

    if(!reports || !reports.products_table)
        return <Spinner animation='border' size='sm' />

    const columns = [
        {
            title: 'Producto',
            dataIndex: 'description',
            render: t => t
        },
        {
            title: 'Color',
            dataIndex: 'color',
            render: t => t ? t : <small><em>No Aplica</em></small>
        },
        {
            title: 'Talla',
            dataIndex: 'size',
            render: t => t ? t : <small><em>No Aplica</em></small>
        },
        {
            title: 'Facturas',
            dataIndex: 'total_invoices',
            render: t => number(t)
        },
        {
            title: 'Tiendas',
            dataIndex: 'total_stores',
            render: t => number(t)
        },
        {
            title: 'Valor Ventas',
            dataIndex: 'total_value',
            render: t => `$ ${number(t)} COP`
        },
        {
            title: 'Unidades',
            dataIndex: 'total_quantity',
            render: t => number(t)
        },
    ]

    const expandedRowRender = r => (
        <React.Fragment>
            <Row>
                <Col>
                    <p className='mb-0'><strong>Id Product: </strong>{r.id}</p>
                    <p className='mb-0'><strong>Codigo LN: </strong>{r.reference}</p>
                </Col>
                <Col>
                    <p className='mb-0'><strong>Categoría: </strong>{r.category}</p>
                    <p className='mb-0'><strong>Sección: </strong>{r.section}</p>
                </Col>
                <Col>
                    <p className='mb-0'><strong>Familia: </strong>{r.family}</p>
                </Col>
            </Row>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <div className='bg-light py-5 px-15 mb-10'>
                <em>Reporte generado para campañas <b>con facturas sincronizadas</b> con fecha de envió entre <b>{currentParams.sent_at_from}</b> y <b>{currentParams.sent_at_until}</b> {currentParams.store_code && ` Y facturas de tienda cuyo codigo contenga: ${currentParams.store_code}`}</em>.
            </div>
            
            <Table
                columns={columns}
                dataSource={reports.products_table.products}
                rowKey={r => r.id}
                locale={{ emptyText: <Result title="No se encontro resultados" status="404" /> }}
                size="small"
                expandable={{ expandedRowRender }}
            />

            <div className='mt-10'>
                <small>Campañas analizadas: {reports.products_table.campaign_ids.join(', ')}</small>
            </div>
        </React.Fragment>
    )
}