import {message} from 'antd'

export const handleError = (error, setLoading = () => {}) => {
   let msg = error.message
   let rsp = error.response

   setLoading(false)

   if(!rsp) return message.error('Error Fatal. Favor comunicarse con soporte@smart4.com.co. ' + msg)

   // If is unauthorize redirect to login
   if(rsp.status === 403 && rsp.config.url !== '/#/auth/login')
      message.error('No posee permisos para esta acción.')
   
   if(rsp.status === 401) window.location.href = '/#/auth/logout'

   // Default messages
   if( rsp.status === 404) msg = 'No se encontraron resultados (404).'
   if( rsp.status === 500) msg = 'Oops error en el servidor. Escriba a soporte@smart4.com.co'

   // If api response has error message set it as default
   if( rsp.data.message ) msg = rsp.data.message

   // Force messages
   if( rsp.status === 422) {      
      msg = ''
      for (let [key, value] of Object.entries(rsp.data.errors)) {
         msg += `[${key}]: ${value.join(', ')}. `
      }
   }

   // Show antd error message
   return message.error(`Algo salio mal. ${msg}`, 10)
}

export const parsePagination = response => {
   let meta = response.data.meta

   return { 
      current_page: meta.current_page, 
      per_page: parseInt(meta.per_page), 
      total: meta.total 
   }
}

export const round = (num, decimals = 2) => (
	Math.round((num + Number.EPSILON) * (10 ** decimals)) / (10 ** decimals)
)

export const number = num => (
	num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
)