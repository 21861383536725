import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Empty, message, Result, Table } from 'antd'
import moment from 'moment'

import { invoicesTableReport } from '../../services'
import { handleError, number } from '../../../../helpers'

export default function InvoicesTableTab({ reports, setReports, filters, activeKey, setLoading }) {
    const [currentParams, setCurrentParams] = useState(null)
    
    useEffect(() => {
        if(activeKey === 'invoices_table' && (!reports || !reports.invoices_table) && filters.sent_at_from){
            invoicesTableReport(filters)
                .then(res => {
                    message.success(res.data.message)
                    setReports({ ...reports, invoices_table: res.data.data })
                    setCurrentParams(res.data.params)                    
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading ])

    if(!filters.sent_at_from)
        return <Empty description="Debe generar el reporte primero" />

    if(!reports || !reports.invoices_table)
        return <Spinner animation='border' size='sm' />

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id'
        },
        {
            title: 'Factura',
            dataIndex: 'reference'
        },
        {
            title: 'Tienda',
            dataIndex: 'sequence_store'
        },
        {
            title: 'Fecha de Venta',
            dataIndex: 'sold_at',
            render: t => moment(t).format('YYYY-MM-DD')
        },
        {
            title: 'Fecha de Entrega',
            dataIndex: 'delivered_at',
            render: t => t ? moment(t).format('YYYY-MM-DD') : <small><em>No aplica</em></small>
        },
        {
            title: 'Valor Total',
            dataIndex: 'total_value',
            render: t => `$ ${number(t)} COP`
        },
        {
            title: 'Total Productos',
            dataIndex: 'total_products',
            render: t => `${number(t)} productos`
        },
        {
            title: 'Total Unidades',
            dataIndex: 'total_quantity',
            render: t => `${number(t)} unidades`
        },
    ]

    return (
        <React.Fragment>
            <div className='bg-light py-5 px-15 mb-10'>
                <em>Reporte generado para campañas <b>con facturas sincronizadas</b> con fecha de envió entre <b>{currentParams.sent_at_from}</b> y <b>{currentParams.sent_at_until}</b> {currentParams.store_code && ` Y facturas de tienda cuyo codigo contenga: ${currentParams.store_code}`}</em>.
            </div>
            
            <Table 
                columns={columns}
                dataSource={reports.invoices_table.invoices}
                rowKey={r => r.id}
                locale={{ emptyText: <Result title="No se encontro resultados" status="404" /> }}
                size="small"
            />

            <div className='mt-10'>
                <small>Campañas analizadas: {reports.invoices_table.campaign_ids.join(', ')}</small>
            </div>
        </React.Fragment>
    )
}