import React from 'react'
import { Result, Table } from 'antd'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

import { number, round } from '../../../helpers'

export default function CampaignsTable({ campaigns }) {

    const columns = [
        {
            title: 'id',
            dataIndex: 'id'
        },
        {
            title: 'Campaña',
            dataIndex: 'name',
            render: t => <small>{t}</small>
        },
        {
            title: 'Enviados',
            dataIndex: 'sent_to_number_of_subscribers',
            render: t => number(t)
        },
        {
            title: 'Aceptados',
            dataIndex: 'sent_to_number_of_subscribers',
            render: (t, r) => `${number(t - r.bounce_count)} (${round(((t - r.bounce_count)/t)*100)}%)`,
        },
        {
            title: 'Aperturas',
            dataIndex: 'open_count',
            render: (t, r) => `${number(t)} (${round(((t)/(r.sent_to_number_of_subscribers - r.bounce_count))*100)}%)`,
        },
        {
            title: 'Clics',
            dataIndex: 'click_count',
            render: (t, r) => `${number(t)} (${round(((t)/(r.sent_to_number_of_subscribers - r.bounce_count))*100)}%)`,
        },
        {
            title: 'Fecha Envió',
            dataIndex: 'sent_at',
            render: t => moment(t).format('YYYY-MM-DD'),
            width: '120px'
        },
        {
            title: 'Sync',
            dataIndex: 'has_subscribers_sync',
            render: t => t ? 'Finalizado' : 'Pend. Fcs',
            width: '90px'
        }
    ]

    const expandedRowRender = (r) => (
        <React.Fragment>
            <Row>
                <Col>
                    <p><strong>Asunto: </strong>{r.subject}</p>
                    <p><strong>Segmento: </strong>{r.segment_name}</p>
                    <p><strong>Estadísticas actualizadas al: </strong>{r.statistics_calculated_at}</p>
                    <p><strong>Campaña valida hasta: </strong>{r.expire_at}</p>
                    <p><strong>Total Aceptados: </strong> {number(r.sent_to_number_of_subscribers - r.bounce_count)}</p>
                    <p><strong>Total Rebotes/Rechazos: </strong>{number(r.bounce_count)} ({round(r.bounce_count/(r.sent_to_number_of_subscribers)*100)}%)</p>
                </Col>
                <Col>
                    <p><strong>Aperturas: </strong>{number(r.open_count)} ({round(r.open_count/(r.sent_to_number_of_subscribers - r.bounce_count)*100)}%)</p>
                    <p><strong>Aperturas Únicas: </strong>{number(r.unique_open_count)} ({round(r.unique_open_count/(r.sent_to_number_of_subscribers - r.bounce_count)*100)}%)</p>
                    <p><strong>Clicks: </strong>{number(r.click_count)} ({round(r.click_count/(r.sent_to_number_of_subscribers - r.bounce_count)*100)}%)</p>
                    <p><strong>Clicks Únicos: </strong>{number(r.unique_click_count)} ({round(r.unique_click_count/(r.sent_to_number_of_subscribers - r.bounce_count)*100)}%)</p>
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Table
                dataSource={campaigns}
                rowKey={r => r.id}
                columns={columns}
                loading={!campaigns}
                locale={{ emptyText: <Result title="No se encontro resultados" status="404" /> }}
                expandable={{ expandedRowRender }}
                size="small"
            />
        </React.Fragment>
    )
}