import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Empty, message, Statistic, Card } from 'antd'

import { salesKpisReport } from '../../services'
import { handleError, number, round } from '../../../../helpers'

export default function SalesKpisTab({ reports, setReports, filters, activeKey, setLoading }) {
    const [currentParams, setCurrentParams] = useState(null)
    
    useEffect(() => {
        if(activeKey === 'sales_kpis' && (!reports || !reports.sales_kpis) && filters.sent_at_from){
            salesKpisReport(filters)
                .then(res => {
                    message.success(res.data.message)
                    setReports({ ...reports, sales_kpis: res.data.data })
                    setCurrentParams(res.data.params)                    
                })
                .catch(error => handleError(error))
                .finally(() => setLoading(false))
        }
    }, [ filters, activeKey, reports, setReports, setLoading ])

    if(!filters.sent_at_from)
        return <Empty description="Debe generar el reporte primero" />

    if(!reports || !reports.sales_kpis)
        return <Spinner animation='border' size='sm' />

    return (
        <React.Fragment>
            <div className='bg-light py-5 px-15 mb-10'>
                <em>Reporte generado para campañas <b>con facturas sincronizadas</b> con fecha de envió entre <b>{currentParams.sent_at_from}</b> y <b>{currentParams.sent_at_until}</b> {currentParams.store_code && ` Y facturas de tienda cuyo codigo contenga: ${currentParams.store_code}`}</em>.
            </div>
            
            { reports.sales_kpis.totals && (
                <Row className='bg-light p-10'>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Total de ventas"
                                value={number(reports.sales_kpis.totals.total_sales)}
                                prefix="$"
                                suffix="COP"
                            />
                        </Card>
                    </Col>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Total de facturas"
                                value={number(reports.sales_kpis.totals.total_invoices)}
                                suffix="facturas"
                            />
                        </Card>
                    </Col>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Total de clientes únicos"
                                value={number(reports.sales_kpis.totals.total_customers)}
                                suffix="clientes"
                            />
                        </Card>
                    </Col>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Ticket Medio por Factura"
                                value={number(round(reports.sales_kpis.totals.total_sales / reports.sales_kpis.totals.total_invoices, 0))}
                                prefix="$"
                                suffix="COP"
                            />
                        </Card>
                    </Col>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Ticket Medio por Cliente"
                                value={number(round(reports.sales_kpis.totals.total_sales / reports.sales_kpis.totals.total_customers, 0))}
                                prefix="$"
                                suffix="COP"
                            />
                        </Card>
                    </Col>
                    <Col sm="6" md="4" className='mb-10'>
                        <Card bordered={false}>
                            <Statistic 
                                title="Campañas Analizadas"
                                value={number(reports.sales_kpis.campaign_ids.length)}
                                suffix="campañas"
                            />
                        </Card>
                    </Col>
                </Row>
            )}
            <div className='mt-10'>
                <small>Campañas analizadas: {reports.sales_kpis.campaign_ids.join(', ')}</small>
            </div>
        </React.Fragment>
    )
}